import axios from 'axios';
import { HOST_API, PUBLIC_USER_JWT_TOKEN } from '../config';
import { isNull } from 'lodash';

const accessToken = window.localStorage.getItem('@reufy-accessToken');

const headers = () => {
  if (accessToken) {
    return {
      Authorization: `Bearer ${accessToken}`,
    };
  }
};

const axiosInstance = axios.create({
  baseURL: HOST_API,
  headers: headers(),
});

const shouldIncludeToken = (url) => {
  const protectedRoutesPattern = /^\/public\/professional\/[a-zA-Z0-9-_]+/;
  return protectedRoutesPattern.test(url);
};

axiosInstance.interceptors.request.use(
  (config) => {
    const accessToken = window.localStorage.getItem('@reufy-accessToken');

    if (shouldIncludeToken(config.url) && isNull(accessToken)) {
      const userPublicJWTToken = PUBLIC_USER_JWT_TOKEN || '';
      window.localStorage.setItem('@reufy-accessToken', userPublicJWTToken);
    }

    if (accessToken) {
      config.headers.Authorization = `Bearer ${accessToken}`;
    }

    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);

axiosInstance.interceptors.response.use(
  response => response,
  error => {
    const { status } = error.response;

    if (status === 401 && error.config.url !== '/auth/login') {
      window.localStorage.removeItem('@reufy-accessToken');
      window.localStorage.removeItem('@reufyUser');
      window.location.href = '/login';

      return Promise.reject(error);
    }

    return Promise.reject(
      (error.response && error.response.data) || 'Something went wrong'
    );
  }
);


export default axiosInstance;

