import { useEffect, useState } from 'react';
import { useLocation, useParams } from 'react-router-dom';
// @mui
import { styled, useTheme } from '@mui/material/styles';
import {
  Alert,
  Box,
  Button,
  Chip,
  Divider,
  Drawer,
  Stack,
  SwipeableDrawer,
  Typography,
} from '@mui/material';
import { ArrowLeft } from '@mui/icons-material';

// hooks
import moment from 'moment';
import useResponsive from '../../hooks/useResponsive';
import useCollapseDrawer from '../../hooks/useCollapseDrawer';
// utils
import cssStyles from '../../utils/cssStyles';
// config
import { NAVBAR } from '../../config';
// components
import Scrollbar from '../Scrollbar';
import { NavSectionVertical } from '../nav-section';
//
import {
  financialNavConfig,
  profissionalNavConfig,
  clinicNavConfig,
  tissNavConfig,
  pacientsNavConfig,
  pacientsNavConfigNotService,
  contractsNavConfig,
  workloadNavConfig,
  roomsConfig,
  reportsConfig,
} from '../nav-section/NavConfig';
import { useDispatch, useSelector } from '../../redux/store';
import {
  setActive,
  setIsEditPacient,
  setOpen,
  setStartDate,
} from '../../redux/slices/navBar';
import Iconify from '../Iconify';
import {
  startAttendance,
  updateAttendance,
} from '../../pages/pacientes/slices/pacientSlice';

import useIsProfessional from '../../hooks/useIsProfessional';
import useContainsAdm from '../../hooks/useContainsAdm';
import useContainsSecretary from '../../hooks/useContainsSecretary';
import { INavbarVertical, INavSection } from './interfaces/navbar.interface';
import AttendanceControls from './atomic-components/organisms/AttendanceControls';
import ScheduleLegends from './atomic-components/organisms/ScheduleLegends';
import { getNextPatientsOfDay } from 'src/redux/slices/schedule/schedule';

// ----------------------------------------------------------------------

const RootStyle = styled('div')(({ theme }) => ({
  [theme.breakpoints.up('lg')]: {
    flexShrink: 0,
    transition: theme.transitions.create('width', {
      duration: theme.transitions.duration.shorter,
    }),
  },
}));

export default function NavbarVertical({
  isOpenSidebar,
  onClickOnScheduleOfDay,
  scheduleHook,
}: INavbarVertical) {
  const theme = useTheme();
  const dispatch = useDispatch();
  const isAdm = useContainsAdm();
  const isSecretary = useContainsSecretary();
  const isProfessional = useIsProfessional();
  const { open, isEditPacient, startDate, hasScheduleLegends } = useSelector(
    (state: any) => state.navBar
  );
  const { nextPatientsOfDay, scheduleId } = useSelector(
    (state: any) => state.schedule
  );
  const { id } = useParams();
  const { user } = useSelector((state: any) => state.user);
  const { activeAttendanceId } = useSelector((state: any) => state.pacients);
  const { pathname } = useLocation();

  const [hasService, setHasService] = useState(false);
  const [finishedAttendance, setFinishedAttendance] = useState<any>(undefined);
  const [dataAttendance, setDataAttendance] = useState<any>();

  const isDesktop = useResponsive('up', 'lg');

  const removeItemById = (config: any[], idToRemove: number) => {
    return config?.map(section => ({
      ...section,
      items: section.items.filter((item: any) => item.id !== idToRemove),
    }));
  };

  const {
    isCollapse,
    collapseClick,
    collapseHover,
    onHoverEnter,
    onHoverLeave,
  } = useCollapseDrawer();

  useEffect(() => {
    if (isOpenSidebar) {
      dispatch(setOpen(false));
    }
  }, [pathname]);

  useEffect(() => {
    const professionalId = scheduleHook?.selectedProfessional?.id ?? '#';
    dispatch(getNextPatientsOfDay(user?.clinic, professionalId) as any);
  }, [scheduleHook?.selectedProfessional]);

  const onCloseSidebar = () => {
    dispatch(setOpen(false));
  };

  const [seconds, setSeconds] = useState(0);
  const [timer, setTimer] = useState<any>(null);

  const onStart = () => {
    dispatch(setStartDate(moment().format()));
    dispatch(setIsEditPacient(true));
  };

  const onEnd = () => {
    dispatch(setIsEditPacient(false));
  };

  useEffect(() => {
    if (isEditPacient) {
      onStart();
      // start countdown
      const t = setInterval(() => {
        setSeconds(s => s + 1);
      }, 1000);
      setTimer(t);
    } else {
      onEnd();
      setTimer(0);
      setSeconds(0);
      if (timer) {
        clearInterval(timer);
      }
    }
  }, [isEditPacient]);

  useEffect(() => {
    if (timer > 0) {
      setHasService(true);
    } else {
      setHasService(false);
    }
  }, [timer]);

  const handleClick = () => {
    if (isEditPacient) {
      const aux = {
        initialDate: moment(startDate).format('YYYY-MM-DD[T]HH:mm:ss.SSS[Z]'),
        finalDate: new Date().toISOString(),
        patient_id: id,
        professional_id: user?.professional,
        schedule_id: scheduleId,
      };
      setDataAttendance(aux);
      setFinishedAttendance(true);
    } else {
      const aux = {
        initialDate: new Date().toISOString(),
        finalDate: new Date().toISOString(),
        patient_id: id,
        professional_id: user?.professional,
        schedule_id: scheduleId,
      };
      dispatch(startAttendance(aux) as any);
      dispatch(setIsEditPacient(!isEditPacient));
    }
  };

  const assignAttendance = async () => {
    setFinishedAttendance(false);
    await dispatch(setIsEditPacient(!isEditPacient));
    await dispatch(updateAttendance(activeAttendanceId, dataAttendance) as any);
    await dispatch(setActive(0));
  };

  const isFinancialPage = () => pathname.includes('admin/financas');
  const isProfessionalPage = () => pathname.includes('admin/profissional');
  const isTissPage = () => pathname.includes('admin/tiss');
  const isPatientsPage = () => pathname.includes('admin/pacientes/');
  const isContracts = () => pathname.includes('admin/contratos');
  const isWorkloads = () => pathname.includes('admin/carga-horaria');
  const isRooms = () => pathname.includes('admin/salas');
  const isReports = () => pathname.includes('admin/relatorios');

  const getProfessionalNavConfig = () => {
    return isProfessional
      ? removeItemById(profissionalNavConfig, 1)
      : profissionalNavConfig;
  };

  const getPacientsNavConfig = () => {
    return hasService ? pacientsNavConfig : pacientsNavConfigNotService;
  };

  const filterNavConfig = (clinicNavConfig: any[], user: any): INavSection[] => {
    return clinicNavConfig?.map((section) => ({
      ...section,
      items: section?.items.filter((item: any) => {
        if (!user?.role.includes('admin') && item?.id === 6) {
          return false;
        }
        return true;
      }),
    }));
  };

  const getNavConfig = () => {
    if (isFinancialPage()) return financialNavConfig;
    if (isProfessionalPage()) return getProfessionalNavConfig();
    if (isTissPage()) return tissNavConfig;
    if (isPatientsPage()) return getPacientsNavConfig();
    if (isContracts()) return contractsNavConfig;
    if (isWorkloads()) return workloadNavConfig;
    if (isRooms()) return roomsConfig;
    if (isReports()) return reportsConfig;

    const filteredClinicNavConfig = filterNavConfig(clinicNavConfig, user);
    return filteredClinicNavConfig;
  };

  const renderContent = (
    <Scrollbar
      sx={{
        height: 1,
        '& .simplebar-content': {
          height: 1,
          display: 'flex',
          flexDirection: 'column',
        },
      }}>
      {scheduleId && pathname.includes('admin/pacientes/') && (
        <AttendanceControls
          isEditPacient={isEditPacient}
          handleClick={handleClick}
          timer={timer}
          seconds={seconds}
        />
      )}
      <>
        <SwipeableDrawer
          onClose={() => {}}
          onOpen={() => {}}
          open={finishedAttendance}
          anchor="right"
          PaperProps={{
            sx: {
              width: isDesktop ? '36%' : '80%',
            },
          }}>
          <Box
            sx={{
              paddingInline: '36px',
              paddingTop: '56px',
              display: 'flex',
              flexDirection: 'column',
              height: '100vh',
              justifyContent: 'space-between',
            }}>
            <Box>
              <Box>
                <Typography variant="h2">Finalizar atendimento</Typography>
                <Typography>
                  Ao finalizar um atendimento, você não poderá alterá-lo
                  novamente. Deseja prosseguir?
                </Typography>
              </Box>

              <Box mt="64px">
                <Typography variant="h3">Assinatura digital</Typography>
                <Typography>Assinar com:</Typography>
                <Box marginBlock="16px">
                  {['Em breve'].map(assignItem => (
                    <Chip
                      disabled
                      size="medium"
                      onClick={() => console.log(assignItem)}
                      variant="outlined"
                      label={assignItem}
                    />
                  ))}
                </Box>
              </Box>
            </Box>

            <Box>
              <Divider />
              <Box
                display="flex"
                alignItems="center"
                justifyContent="space-between"
                height="88px">
                <Button
                  onClick={() => setFinishedAttendance(false)}
                  variant="text"
                  size="large">
                  <ArrowLeft />
                  Voltar
                </Button>
                <Button
                  onClick={() => assignAttendance()}
                  variant="contained"
                  size="large">
                  Finalizar
                </Button>
              </Box>
            </Box>
          </Box>
        </SwipeableDrawer>
      </>

      {!pathname.includes('admin/agenda') ? (
        <NavSectionVertical
          navConfig={getNavConfig()}
          isCollapse={isCollapse}
        />
      ) : (
        <Box sx={{ px: 2 }}>
          <Box mt={4} />
          <Typography variant="overline" color="text.secondary" sx={{ ml: 2 }}>
            Próximos Pacientes
          </Typography>
          {nextPatientsOfDay.length === 0 ? (
            <Alert severity="info" sx={{ mt: 2 }}>
              Nada para mostrar
            </Alert>
          ) : (
            <Stack spacing={1} sx={{ mt: 3 }}>
              {nextPatientsOfDay?.map((schedule: any, i: any) => (
                <Stack
                  key={i}
                  spacing={1}
                  direction="row"
                  alignItems="center"
                  sx={{
                    '&:hover': {
                      backgroundColor: theme =>
                        schedule.status === 'confirmed'
                          ? theme.palette.info.light
                          : schedule.status === 'scheduled'
                          ? theme.palette.grey[200]
                          : theme.palette.warning.light,
                      cursor: 'pointer',
                    },
                    borderRadius: 1,
                    schedule: 0.4,
                  }}
                  onClick={() => onClickOnScheduleOfDay(schedule)}>
                  <Box
                    width={36}
                    height={36}
                    display="flex"
                    justifyContent="center"
                    alignItems="center"
                    sx={{
                      borderRadius: 1,
                      backgroundColor: theme =>
                        schedule.status === 'confirmed'
                          ? theme.palette.info.light
                          : schedule.status === 'scheduled'
                          ? theme.palette.grey[200]
                          : theme.palette.warning.light,
                      color: theme =>
                        schedule.status === 'confirmed'
                          ? theme.palette.info.dark
                          : schedule.status === 'scheduled'
                          ? theme.palette.grey[600]
                          : theme.palette.warning.dark,
                    }}>
                    <Iconify
                      width={16}
                      height={16}
                      icon={
                        schedule.status === 'confirmed'
                          ? 'mdi:calendar-check'
                          : schedule.status === 'scheduled'
                          ? 'mdi:calendar-blank'
                          : 'ic:outline-access-time'
                      }
                      sx={undefined}
                    />
                  </Box>
                  <Stack>
                    <Typography
                      variant="caption"
                      sx={{
                        color: theme =>
                          schedule.status === 'confirmed'
                            ? theme.palette.info.dark
                            : schedule.status === 'scheduled'
                            ? theme.palette.grey[600]
                            : theme.palette.warning.dark,
                      }}>
                      {schedule.startTime} - {schedule.endTime}
                    </Typography>
                    <Typography
                      variant="caption"
                      fontWeight={900}
                      sx={{
                        color: theme =>
                          schedule.status === 'confirmed'
                            ? theme.palette.info.dark
                            : schedule.status === 'scheduled'
                            ? theme.palette.grey[600]
                            : theme.palette.warning.dark,
                      }}>
                      {schedule.patient?.name}{' '}
                      {isAdm || isSecretary
                        ? `- ${schedule.professional?.name}`
                        : ''}
                    </Typography>
                  </Stack>
                </Stack>
              ))}
            </Stack>
          )}
        </Box>
      )}

      <Box pb={10} />
    </Scrollbar>
  );

  return (
    <>
      <RootStyle
        sx={{
          width: {
            lg: isCollapse
              ? NAVBAR.DASHBOARD_COLLAPSE_WIDTH
              : NAVBAR.DASHBOARD_WIDTH,
          },
          ...(collapseClick && {
            position: 'absolute',
          }),
        }}>
        {!isDesktop && (
          <Drawer
            open={open}
            onClose={onCloseSidebar}
            PaperProps={{ sx: { width: NAVBAR.DASHBOARD_WIDTH } }}>
            {renderContent}
          </Drawer>
        )}

        {isDesktop && (
          <Drawer
            open
            variant="persistent"
            onMouseEnter={onHoverEnter}
            onMouseLeave={onHoverLeave}
            PaperProps={{
              sx: {
                mt: isDesktop ? '64px' : '0px',
                width: NAVBAR.DASHBOARD_WIDTH,
                borderRightStyle: 'inherit',
                bgcolor: 'background.default',
                transition: theme =>
                  theme.transitions.create('width', {
                    duration: theme.transitions.duration.standard,
                  }),
                ...(isCollapse && {
                  width: NAVBAR.DASHBOARD_COLLAPSE_WIDTH,
                }),
                ...(collapseHover && {
                  ...cssStyles(theme).bgBlur(),
                  boxShadow: theme => (theme as any).customShadows.z24,
                }),
              },
            }}>
            {renderContent}
            {hasScheduleLegends && <ScheduleLegends />}
          </Drawer>
        )}
      </RootStyle>
    </>
  );
}
